import { isTB } from '../consist/config'

export default () => {
    $('.x-clamp-title').shave(60, { spaces: false })

    if (isTB) {
        $('.x-clamp-description').shave(60, { spaces: false })
        $('.x-clamp-description-three').shave(90, { spaces: false })
    } else {
        $('.x-clamp-description').shave(40, { spaces: false })
        $('.x-clamp-description-three').shave(60, { spaces: false })
    }
}