import nav from './util/nav'
import dispatcher from './util/dispatcher'
import animate from './util/animate.js'

import ellipsis from './util/ellipsis'
import swiperBanner from './util/swiperBanner'
import renderFBPage from './util/renderFBPage'
import collapseList from './util/collapseList'
import scrollTo from './util/scrollTo'


const Route = {
    all: '.'
}

/*
 * on domContentLoaded
 */
$(function() {

    dispatcher(Route.all, () => {

        FastClick.attach(document.body)

        nav()

        animate()

        swiperBanner()

        ellipsis()

        renderFBPage()

        scrollTo()

        collapseList.bind('.x-collapse-container', '.x-collapse-item')

    })

    dispatcher(location.pathname)

})