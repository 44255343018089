const init = () => {}

const bind = () => {
    $('.x-collapse-item').on('click', function() {
        let $container = $(this).parents('.x-collapse-container')
        let isCollapse = $(this).hasClass('is-collapse')

        if (isCollapse) {
            $container.find('.x-collapse-item').addClass('is-collapse')
            $container.find('.cities').addClass('is-collapse')

            console.log($container.find('.x-collapse-item'))
            $(this).removeClass('is-collapse')
            $(this).next().removeClass('is-collapse')
        } else {
            $(this).addClass('is-collapse')
            $(this).next().addClass('is-collapse')
        }
    })
}

export default {
    init,
    bind
}