import modalHelper from './modalHelper'

export default () => {
  $('.icon-menu').on('click', function () {
    const $menuBord = $('.x-header-nav')

    if ($(this).hasClass('is-expand')) {
      modalHelper.looseBody()
    } else {
      modalHelper.fixedBody()
    }

    $(this).toggleClass('is-expand')
    $menuBord.toggleClass('is-expand')
  })

  $('.x-header-nav').on('touchmove', function (e) {
    e.preventDefault();
  })

}