let path_func = []

export default (path, callback) => {
  path_func = path_func || []

  if (callback)
    return path_func.push([path, callback])

  for (let i = 0, l = path_func.length; i < l; ++i) {
    let func = path_func[i]
    let match = ''

    if (path.match('toList$')) {
      match = path.replace(/\/preview/, '').match(func[0] + '$');
    } else {
      match = path.replace(/\/preview/, '').match(func[0]);
    }

    match && func[1](match)
  }
}