export default () => {
    const scrollTo = ($this) => {
        const id = $this.data('id')
        let $target = $('#' + id)

        if ($target.length) {
            $('html,body').animate({
                scrollTop: $target.offset().top - 96
            }, 900)
            return false
        }
    }

    $('.x-facebook').on('click', function() {
        scrollTo($(this))
    })

    $('.x-scroll-to-bottom').on('click', function() {
        scrollTo($(this))
    })
}