export default () => {
    const $scroll = $('.scroll')
    let scrollValue = 0

    const animation = () => {
        scrollValue = $(window).scrollTop()

        $scroll.each(function() {
            console.log(scrollValue + window.innerHeight)
            console.log('ddd' + $(this).offset().top)
            if (scrollValue + window.innerHeight >= $(this).offset().top) {
                $(this).addClass('is-scoped')
            }
        })
    }

    const bind = () => {
        $(window).scroll(function() {
            animation()
        })
    }

    bind()
    animation()
}