export default () => {
    let _options = {
        autoplay: true,
        loop: true,
        pagination: {
            el: '.swiper-pagination'
        },

    }

    let newSwiper = new Swiper('.swiper-container', _options)
}